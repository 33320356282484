module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-71700644-2","head":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-intercom-spa/gatsby-browser'),
      options: {"plugins":[],"app_id":"d7vu7om5"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mixpanel/gatsby-browser'),
      options: {"plugins":[],"apiToken":"d6e013f46ee1a5160a1dc40eaf3f3178","head":true,"pageViews":{"/":"Work","/404":"404","/about":"About","/approach":"Approach","/cambrdige-audio":"Cambrdige Audio","/laterooms":"Laterooms","/Hellocar":"Hellocar"}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
